import React from 'react'

const RecentCauses = () => {
  return (
    <div>
                <section class="team-section bg-grey bd-bottom circle shape padding">
            <div class="container" style={{marginTop:"13%"}}>
                <div class="section-heading text-center mb-40">
                    <h2>Our Ministries</h2>
                    <span class="heading-border"></span>

                  
                </div>
                <div class="team-wrapper row">
                    <div class="col-lg-12 sm-padding">
                        <div class="team-wrap row">
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/education_ministry_3.jpeg" alt="team"/>
                                    <div class="hover">
                                     
                                       
                                       
                                    </div>
                                    
                                </div>
                                <h3>Children Ministry</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/helpingpoor.jpg" alt="team"/>
                                    
                                </div>
                                <h3> Underprivileged out reach. </h3>

                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                    <img src="img/Untitled design (6).jpg" alt="team"/>
                                    <div class="hover">
                                       
                                    </div>
                                </div>
                                <h3>Youth Ministry</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/Untitled design (7).jpg" alt="team"/>
                                    <div class="hover">
                                     
                                       
                                       
                                    </div>
                                    
                                </div>
                                <h3>Prayer Ministry</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/Untitled design (8).jpg" alt="team"/>
                                    <div class="hover">
                                    </div>
                                </div>
                                <h3> Womens Ministry</h3>

                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                    <img src="img/Untitled design (9).jpg" alt="team"/>
                                    <div class="hover">
                                       
                                    </div>
                                </div>
                                <h3>Village Ministry</h3>
                            </div>


                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/Untitled design (10).jpg" alt="team"/>
                                    <div class="hover">
                                     
                                       
                                       
                                    </div>
                                    
                                </div>
                                <h3>Outreach Ministry</h3>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/netowrk.jpeg" alt="team"/>
                                    <div class="hover">
                                        
                                    </div>
                                </div>
                                <h3> Pastoral network.</h3>

                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                    <img src="img/Untitled design (11).jpg" alt="team"/>
                                    <div class="hover">
                                       
                                    </div>
                                </div>
                                <h3>Convention/ Crusade</h3>
                            </div>
                            
                       
                        </div>
                    </div>
               
                    
                </div>
            </div>
        </section>  
        <section class="causes-section bg-grey bd-bottom padding">
            {/* <div class="container">
                <div class="section-heading text-center mb-40">
                    <h2>Our Ministers</h2>
                    <span class="heading-border"></span>
                    <p>Help today because tomorrow you may be the one who <br/> needs more helping!</p>
                </div>
                
            </div> */}
    
    
    {/* next */}
    <section class="causes-section bg-grey bd-bottom padding">
            <div class="container">
               
                <div class="causes-wrap row">
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/onepioc.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Sharing the word of God. Lahore church</h3>
        
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/onepic2.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Giving the Word of Prophecy. Lahore church</h3>
                              
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/onethree.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Praying for Job blessings</h3>
                            
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="causes-wrap mt-4 row">
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/onefive.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Praying for young girl Education</h3>
        
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/onesix.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Praying for Joints Pain. Youhanabad Renwel center Lahore</h3>
                              
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/oneseven.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Praying for Deliverance</h3>
                            
                            </div>
                        </div>
                    </div>
                    <div class="causes-wrap mt-4 row">
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/edution.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Eduction "Ministry"</h3>
                                <p>Free schools for underprivileged area that have no schools or any educational institutions for kids to get educated. Bricklaying communities, rural area of Pakistan, 80% of the children are forced to work and earn money.</p>
                               
                               
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/prayer.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Prayer Ministry</h3>
                                <p>praying for healing</p>
                                
                                {/* <a href="#" class="read-more">Read More</a> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 xs-padding">
                        <div class="causes-content">
                           <div class="causes-thumb">
                                <img src="img/unreach.png" alt="causes"/>
                                {/* <a href="#" class="donate-btn">Donate Now<i class="ti-plus"></i></a> */}
                                <div class="progress">
                                <div class="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="wow cssanimation fadeInLeft"></span></div>
                                </div>
                           </div>
                            <div class="causes-details">
                                <h3>Reaching the Unreached</h3>
                                <p>Go ye therefore, and teach all nations, baptizing them in the name of the Father and of the Son and of the Holy Ghost: <br/>  <b>Matthew 28:19</b></p>
                               
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            
        </section>
        </section>
    </div>

  )
}

export default RecentCauses