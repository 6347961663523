import React from "react";
import SliderHero from "./SliderHero";
const promo = () => {
  return (
    <div>
      <section class="slider-section">
        <div class="slider-wrapper">
          <SliderHero />
        </div>
      </section>
    </div>
  );
};

export default promo;
