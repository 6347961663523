import React from 'react'
import Footer from '../components/Footer';
import Header from '../components/Header';
import RecentCauses from '../components/RecentCauses';

const OurMinistery = () => {
  return (
    <>
    <Header/>
<RecentCauses/>
<Footer/>      

    </>
  )
}

export default OurMinistery;
