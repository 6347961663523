import React from 'react'
import { Link } from 'react-router-dom'
const Team = () => {
  return (
    <div>
         <section class="team-section bg-grey bd-bottom circle shape padding">
            <div class="container">
                <div class="section-heading text-center mb-40">
                    <h2> Flooding  </h2>
                    <span class="heading-border"></span>
                    <p>Recent flooding in Pakistan have brought a lot pain.  In a large amount of family's, they have displaced  from their homes,villages, cities to no shelters and no roof on their heads. Few of our church familes have been affected by recent flood. Please pray and help them to get shelter over their heads along with their kids</p>
                    <p style={{color:"green"}}>Albert international ministry is a non-profit entity registered as 501 (c)(3). All donations made to Albert international ministry are tax exempt from federal income tax.
                    
                    </p>
                    {/* <Link to="/not-found"   class="default-btn">Give Now</Link> */}
                    {/* <a href="https://link.clover.com/urlshortener/kWwcf5" 
                                style={{backgroundColor:'#f7b865',border:'1px solid #f7b865',minHeight:'50px',color:'#fff',padding:'10px 20px',textDecoration:'none',bordeRadius:'4px'}}
                                >Give Now</a> */}


                </div>
                <div class="team-wrapper row">
                    <div class="col-lg-12 sm-padding">
                        <div class="team-wrap row">
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/fs.jpeg" alt="team"/>
                                    <div class="hover">
                                     
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                   <img src="img/Untitled design (18).jpg" alt="team"/>
                                    <div class="hover">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="team-details">
                                    <img src="img/Untitled design (19).jpg" alt="team"/>
                                    <div class="hover">
                                    </div>
                                </div>
                            </div>
                       
                        </div>
                    </div>
               
                    
                </div>
            </div>
        </section>
    </div>
  )
}

export default Team