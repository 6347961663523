import React from 'react'
import SliderComp from "./SliderComp"
const Testimonial = () => {
    return (
        <div>
            <section class="testimonial-section bd-bottom padding ">
                <div class="container" style={{ marginTop: '20%' }}>
                    <div class="section-heading text-center mb-40">
                        {/* <h2>What People Say</h2>
                    <span class="heading-border"></span>
                    <p>Help today because tomorrow you may be the one who <br/> needs more helping!</p> */}
                    </div>
                    <SliderComp />
                    <div id="testimonial-carousel" class="testimonial-carousel owl-carousel">
                        <div class="testimonial-item">
                            <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p>
                            <div class="testi-footer">
                                <img src="img/team-1.jpg" alt="profile" />
                                <h4>Jonathan Smith <span>Marketer</span></h4>
                            </div>
                        </div>
                        <div class="testimonial-item">
                            <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p>
                            <div class="testi-footer">
                                <img src="img/team-2.jpg" alt="profile" />
                                <h4>Angelina Rose <span>Designer</span></h4>
                            </div>
                        </div>
                        <div class="testimonial-item">
                            <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p>
                            <div class="testi-footer">
                                <img src="img/team-3.jpg" alt="profile" />
                                <h4>Taylor Swift <span>Developer</span></h4>
                            </div>
                        </div>
                        <div class="testimonial-item">
                            <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p>
                            <div class="testi-footer">
                                <img src="img/team-4.jpg" alt="profile" />
                                <h4>Michel Brown <span>Programer</span></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blog-section bg-grey bd-bottom padding">
                <div class="container">
                    <div class="section-heading text-center mb-40">
                        {/* <h2>Testimoney</h2> */}
                        <span class="heading-border"></span>
                        {/* <p>Help today because tomorrow you may be the one who <br/> needs more helping!</p> */}
                    </div>
                    <div class="row">
                        <div class="col-lg-12 xs-padding">
                            <div class="blog-items grid-list row">
                                <div class="col-md-4 padding-15">
                                    <div class="blog-post">
                                        <img src="img/Untitled design (17).jpg" alt="blog post" />
                                        <div class="blog-content">
                                            {/* <span class="date"><i class="fa fa-clock-o"></i> January 01.2021</span> */}
                                            <h3><a href="#">Testimony</a></h3>
                                            <p>Pastor Naseem Albert Visited Lahore Pakistan in 10th of December and requested for Children Blessing, and we are so much thankful to Almighty God and Pastor Albert. Lord blessed  us with baby boy.</p>
                                            {/* <p>The secret to happiness lies in helping others. Never underestimate the difference YOU can make in the lives of the poor, the abused and the helpless.</p> */}
                                            <a href="#" class="post-meta">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 padding-15">
                                    <div class="blog-post">
                                        
                                        <div class="blog-content">
                                            {/* <span class="date"><i class="fa fa-clock-o"></i> January 01.2021</span> */}
                                            <h3><a href="#">Vision</a></h3>

                                            <p>Our Purpose is to build the Kingdom of God By Meeting the needs of People.</p>
                                            <p>Objectives.<br />
                                                OUR Mission is to meet the Needs of the People which are?</p>
                                            {/* <ul class="check-list"> */}

                                            <li>The need for Salvation I Tim 2; 3,4</li>
                                            <li>The need for Financial Support = Ecc 4; 9, 12</li>
                                            <li> The Need for Spirituality, ACTS = 17; 26, 27 </li>
                                            <li> The Need For Succession, Ecc 2; 24 ( Ecc 3;</li>


                                            {/* </ul> */}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 padding-15">
                                    <div class="blog-post">
                                        
                                        <div class="blog-content">
                                            {/* <span class="date"><i class="fa fa-clock-o"></i> January 01.2021</span> */}
                                            <h3><a href="#">FAITH,</a></h3>

                                            <p>We are Pentecostal believers, Trusting in Father SON And HOLY SPIRIT in
                                                Everything BY FAITH.</p>
                                            <p>Objectives.<br />
                                                OUR Mission is to meet the Needs of the People which are?</p>
                                            {/* <ul class="check-list"> */}

                                            <li>We Believe in Water Baptism Mt. 28/19 Speaking In Tongues.</li>
                                            <li>We Believe every word in Bible is Written by THE HOLY SPIRIT.</li>
                                            <li> We are Devoted and Committed leaders working Hard to Win Souls for Jesus
                                                Christ and making Disciples. </li>
                                            <li>We have accepted the Challenges, to spread The Gospel Throughout the
                                                World. Mt 28/ 19. Mk</li>
                                            <li>We believe in Second Coming of Jesus Christ And The Judgment Day.</li>


                                            {/* </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimonial