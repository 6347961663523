import React, { Component } from "react";
import Slider from "react-slick";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
      <div>
       
        <Slider {...settings}>
          <div  >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg"/>
        
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.04 AM.jpeg"/>
        
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.02 AM.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.03 AM.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img//WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/WhatsApp Image 2022-10-03 at 2.10.02 AM (1).jpeg"/>
          </div>
        </Slider>
      </div>
    );
  }
}