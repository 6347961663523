import React from 'react'
import Header from '../components/Header'
import SliderComp from "../components/SliderComp"
import Footer from '../components/Footer'
import GallerySilder from "../components/GallerySilder"
const Gallery = () => {
  return (
    <div>
        <Header/>
        <div>
         {/* <div class="pager-header">
            <div class="container"  style={{marginTop:'30%'}}>
                <div class="page-content">
                    <h2>A Third  of  Pakistan Underwater amin its worst Floods in  History</h2>
                    <p>Help today because tomorrow you may be the one who <br/>needs more helping!</p>
                   
                </div>
            </div>
        </div> */}
          <section class="gallery-section bg-grey bd-bottom padding ">
            <div class="container" style={{marginTop:"13%"}}>
               <div class="section-heading text-center mb-40">      
                    <h2>Aim International Ministry Gallery </h2>
                    <span class="heading-border"></span>
                </div>
                <div class="gallery-items ">
                  <GallerySilder/>
                  
                </div>
                <div class="gallery-items row">
                    <div class="col-lg-4 col-sm-6 single-item branding design">
                        <div class="gallery-wrap">
                            <img src='img/WhatsApp Image 2022-10-03 at 2.09.58 AM.jpeg' alt=""/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.09.58 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.09.59 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.09.59 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item wordpress design branding">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.00 AM (1).jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.00 AM (1).jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item design branding wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.00 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.00 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item branding marketing">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.01 AM (1).jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.01 AM (1).jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing design">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.03 AM (1).jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.03 AM (1).jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="gallery-items row">
                    <div class="col-lg-4 col-sm-6 single-item branding design">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.05 AM (1).jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.05 AM (1).jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item wordpress design branding">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.04 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.04 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item design branding wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.03 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.03 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item branding marketing">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing design">
                        <div class="gallery-wrap">
                            <img src="img/gallery01.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/gallery01.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="gallery-items row">
                    <div class="col-lg-4 col-sm-6 single-item branding design">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.02 AM (1).jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.02 AM (1).jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.02 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.02 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item wordpress design branding">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.01 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item design branding wordpress">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.03 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.03 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item branding marketing">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.00 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.00 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing design">
                        <div class="gallery-wrap">
                            <img src="img/WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/WhatsApp Image 2022-10-03 at 2.10.05 AM.jpeg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        </section>
    </div>
    <Footer/>
    </div>

  )
}

export default Gallery