// Init
import React from "react";
import { Route, Switch } from "react-router-dom";

// Routes
import Home from "../containers/Home.js";
import Gallery from "../containers/Gallery";
import Contact from "../components/Contact.js";
import About from "../containers/About"
import Testimoney from "../containers/Testimoney.js";
import Donate from "../containers/Donate.js";
import OurMinistery from "../containers/OurMinistery.js";
import Maintain from "../containers/Maintain.js";
const index = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route  path="/gallery" component={Gallery} />
      <Route  path="/contact" component={Contact} />
      <Route  path="/about" component={About} />
      <Route  path="/testimoney" component={Testimoney} />
      <Route  path="/donate" component={Donate} />
      <Route  path="/ourministery" component={OurMinistery} />
      <Route  path="/not-found" component={Maintain} />


      
    </Switch>
  );
};

export default index;
