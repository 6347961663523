// import React, { Component } from "react";
// import Slider from "react-slick";
// // import "~slick-carousel/slick/slick.css"; 
// // import "~slick-carousel/slick/slick-theme.css";
// export default class SliderComp extends Component {
//   render() {
//     var settings = {
//       dots: true,
//       infinite: false,
//       speed: 500,
//       slidesToShow: 4,
//       slidesToScroll: 4,
//       initialSlide: 0,
//       responsive: [
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 3,
//             slidesToScroll: 3,
//             infinite: true,
//             dots: true
//           }
//         },
//         {
//           breakpoint: 600,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 2,
//             initialSlide: 2
//           }
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1
//           }
//         }
//       ]
//     };
//     return (
//       <div>
//         <h2> Responsive </h2>
//         <Slider {...settings}>
//           <div>
//             <h3>
//               <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//             </h3>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//           <div>
//           <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT05anM9pYBKFjOiyl2Ila9RNNoreLs3hlllA&usqp=CAU"/>
//           </div>
//         </Slider>
//       </div>
//     );
//   }
// }

import React, { Component } from "react";
import Slider from "react-slick";

export default class AutoPlay extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
      <div>
       
        <Slider {...settings}>
          <div  >
          <img style={{width:"400px", height:"250px"}} src="img/movie1.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move2.jpeg"/>
        
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move3.jpeg"/>
        
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move5.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move6.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move7.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move9.jpeg"/>
          </div>
          <div >
          <img style={{width:"400px", height:"250px"}} src="img/move10.jpeg"/>
          </div>
        </Slider>
      </div>
    );
  }
}