import React from 'react'
import Donatepage01 from '../components/Donatepage01';
import Footer from '../components/Footer';
import Gallery from '../components/Gallery';
import Header from '../components/Header';
// import Testimonial from '../components/Testimonial';

const Donate = () => {
  console.log('aiminternation')
  
  return (
    <>
   <Header/>
   <Gallery/>
   <Donatepage01/>
   <Footer/>

    </>
  )
}

export default Donate;