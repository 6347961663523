import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
const About = () => {
    return (
        <div>

            <Header />

            <section class="about-section bg-grey bd-bottom padding">
                <div class="container" style={{ marginTop: "10%" }}>
                    <div class="row about-wrap">
                        <div class="col-md-12 xs-padding">
                            <div class="about-image">
                                <img src="img/Untitled design (12).jpg" alt="about image" />
                            </div>
                        </div>
                        <div class="col-md-12 xs-padding" style={{ textAlign: 'center' }}>
                            <div class="about-content" style={{ textAlign: 'center' }}>
                                <h2>AIM International Ministry</h2>
                                <h4>AIM International Ministry</h4>

                                <p>I was born in Pakistan in Province of Sind,  on 3rd March 1950, I am second born in Family. And 3rd Generation Christian.
                                    When I was one Year old, I lost my Eyes Sight and some of Friends of my Parents advised them to take me
                                    your Son to a which craft to prayer for me.  My father told them that we are Christians and we do not believe
                                    in theses things, one day a man of  God to our house and prayed for me and by THE GRACE of God,
                                    I received my eyes sight. And my Parents accepted JESUS CHRIST as their personal Lord and Savior.
                                    In 1973,  One Of my Friend Advised  to go to Kuwait.  And I was living my life there, My Parents kept asking me to go to Church,
                                    Some how in 1983, I went back to Pakistan, and got Married A girl from Lahore and left my wife with my Family in Karachi.
                                    And I went back to Kuwait.One day in Kuwait while I was going to see my Friend and as I was Standing on bus Stand I HEARD VERY CLEAR VOICE SAYING TO ME,
                                    Why you do not obey to Your Father:s  letter and go to Church,
                                    I said to my self Its only God who speaks, what do you want me to do

                                    After this I started looking for Church In Kuwait, came in my mind my father use to say go URDU Speaking Church.  I found a Church and I went in Church and I saw a Preacher was from USA, I was only waiting when Preacher will finish and will give alter Call,
                                    And I accepted Jesus Christ As my LORD and Savior, And requested to Preacher to give me water Baptism 1984

                                    After this I realized that I am a married man, I decided to go back home Pakistan, I don’t now why But I canceled my Kuwait Resident VISA In 1985.
                                    I came back to Pakistan Karachi  When Holy Spirit was dealing with me In Kuwait, I came to know when I reach Home my Wife told, she took Water Baptism from our mother Church  AOG Drigh Road Pastor Zia Paul

                                    I than I told her all story how I heard GODS Voice in Kuwait At Bus Stand,
                                    My Wife Received Holy Spirit Before Me, I received HOLY SPIRIT after one Year while I was in England Bible College.
                                    We started going to our mother Church and Started helping my Pastor, AOG.

                                    And now my Mother Started saying that you don’t have children you get Married, Another Girl..  I told my mother Wait Mom
                                    And we don’t know Gods Plane we went to see, Yasmeens Parents in Lahore,
                                    And next day was Sunday 11th of August  as Church Finished I said in my Mouth Lord That Lady will come and say I want to Pray for If you wanted me to bless With children from the Girl I have Marred. As I whispered to my self, that lady I do not know about her, she came and she said  Can I Pray with yoy  THANKS TO HOLY SPIRIT SHE CAME AND PRAYED FOR US
                                    Right after 8 month we went for Checkup in Hospital UCH Is Christian Hospital, when my wife out she told me Doctor are Saying under Baby there is a Ball of Cancer, and we cannot touch right now we will wait tell the day comes for Baby birth.
                                    Same Pastor who Baptism me In Kuwait,  DR. Bajat From USA was Visiting in Pakistan Lahore AT FGA Church Pastor Sardars some how I went to meeting And Pastor Asked me where is YASMEEN my wife, I said to Pastor she is not feeling well, he said tomorrow is last meeting You must bring Her</p>
                                <a href="#" class="default-btn">More About Us</a>
                            </div>
                            {/*  */}
                            <div class="about-content" style={{ textAlign: 'center' }}>
                                <h2 style={{ marginTop: '10%' }}>FAITH</h2>
                                <h4>Pastor NASEEM ALBERT RASHID</h4>
                                <p>We are Pentecostal believers, Trusting in Father SON And HOLY SPIRIT in Everything BY FAITH.

                                    We Believe in Water Baptism  Mt. 28/19 Speaking In Tongues.
                                    We Believe every word in Bible is Written by THE HOLY SPIRIT.
                                    We are Devoted and Committed leaders working Hard to Win Souls for Jesus Christ and making Disciples.
                                    We have accepted the Challenges, to spread The Gospel Throughout the World. Mt 28/ 19. Mk
                                    We believe in Second Coming of Jesus Christ And The Judgment Day.
                                </p>

                                <a href="#" class="default-btn">More About Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="team-section bg-grey bd-bottom circle shape padding">
                <div class="container">
                    <div class="section-heading text-center mb-40">
                        <h2>AIM International Ministry.</h2>
                        <span class="heading-border"></span>
                    </div>
                    <div class="team-wrapper row">
                        <div class="col-lg-6 sm-padding">
                            <div class="team-wrap row">
                                <div class="col-md-6">
                                    <div class="team-details">
                                        <img src="img/Untitled design (13).jpg" alt="team" />
                                        <div class="hover">

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="team-details">
                                        <img src="img/Untitled design (14).jpg" alt="team" />
                                        <div class="hover">

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="team-details">
                                        <img src="img/Untitled design (15).jpg" alt="team" />
                                        <div class="hover">

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="team-details">
                                        <img src="img/Untitled design (16).jpg" alt="team" />
                                        <div class="hover">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 sm-padding">
                            <div class="team-content">
                                <h2>AIM International Ministry.</h2>

                                <p>We have Four Provinces in Pakistan, I was able to Visit and Preach Gospel,  many came to the Lord and Many were Healed through our Ministry. My older son said in my heart is to start TV Christian Channel, I told him to do it For Gods Glory, many testimony came that Pepole are blessed through our TV Program, Gospel Songs and Kids short store and Short message and Praying for Needy People. Our Christian TV Channel was as many colonies, and city.

                                    And by His Grace we were able to start  Bible School in Lahore 3 years FREE Education, We Provided them three years, Boarding and Lodging for free.
                                    We took (9) girls and (13) boys. From different citys and Villages,  buy this I was able to reach more Village and cities through Students.

                                    God Opened door for me to Visit for Sharing Word of GOD, DUBI Zambia, Philppines and in Philppines I was able to preach on Radio, and I was 2006 I was Able to Preach in USA.
                                </p>
                                <ul class="check-list">
                                    <li style={{ fontWeight: "bolder" }}> Ministries :</li>
                                    <li> Children Ministry</li>
                                    <li> Helping Poor.</li>
                                    <li> Womens  Ministry </li>
                                    <li> Village Ministry</li>
                                    <li> Outreach Ministry</li>
                                    <li> Convention/ Crusade</li>
                                    <li> Pastoral network</li>

                                </ul>
                                <a href="#" class="default-btn">Join With Us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

        </div>
    )
}

export default About