import React from 'react'

const About = () => {
    return (
        <div >
            <section class="about-section bd-bottom shape circle padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 xs-padding">
                            <div class="profile-wrap">
                                <img class="profile" src="img/ABOUT.png" alt="profile" />
                                <h3>NASEEM ALBERT RASHID <span>Founder of AIM.</span></h3>
                                <p>Pastor Naseem was born in Karachi Pakistan into a pastor house, oldest of nine siblings. He is a second generation servant of God. Pastor Naseem’s testimony starts when he was only one year old, he lost his eye sight and some of Friends of my
                                    Parents advised them to take me  your Son  to a which craft to prayer for me.
                                    My father told them that we are Christians and we do not believe in theses
                                    things, one day a man of God to our house and prayed for me and by THE
                                    GRACE of God, I received my eyes sight. And my Parents accepted JESUS
                                    CHRIST as their personal Lord and Savior.</p>
                                {/* <img src="img/sign.png" alt="sign"/> */}
                            </div>
                        </div>
                        <div class="col-md-8 xs-padding">
                            <div class="about-wrap row">
                                <div class="col-md-6 xs-padding">
                                    <img src="img/family01.jpeg" alt="about-thumb" />
                                   
                                </div>
                                <div class="col-md-6 xs-padding">
                                    <img src="img/family02.jpeg" alt="about-thumb" />
                                   
                                </div>
                            </div>
                            <div class="about-wrap row">
                                <div class="col-md-6 xs-padding">
                                    <img src="img/family03.jpg" alt="about-thumb" />
                                   
                                </div>
                                <div class="col-md-6 xs-padding">
                                    <img src="img/family04.jpg" alt="about-thumb" />
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About
