// Init
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { StoreProvider } from "./StoreContext";
import "./css/font-awesome.min.css"
import "./css/themify-icons.css"
import "./css/font-awesome.min.css"
import "./css/elegant-font-icons.css"
import "./css/bootstrap.min.css"
import "./css/venobox/venobox.css"
import "./css/slicknav.min.css"
import "./css/owl.carousel.css"
// import "./css/slicknav.min.css"
// import "./css/css-animation.min.css"
import "./css/main.css"
// import "./css/responsive.css"





// Rendering DOM
ReactDOM.render(
  <StoreProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
