import React from "react";
import { Link } from "react-router-dom";
import Donatepage01 from "./Donatepage01";
const DonateLandingPage = () => {
  return (
    <>
      {/* <h2>Prayer Request</h2>
            <span class="heading-border"></span> */}
      <div class="container">
        {/* <h2 style={{textAlign:"center",marginTop:'5%'}}>Give Now</h2> */}
        {/* <span class="heading-border"></span> */}
        <div class="row contact-wrap border">
          <div class="col-md-6 xs-padding">
            <div class="contact-info">
              <h3>Albert International Ministry</h3>
              <p>Help Us to Reach the Un-Reached.</p>
              <p>
                Giving is not just about making a donation, it’s about Making a
                Difference..
              </p>
              {/* <p style={{color:"green"}}><b>Send Your Donations / Love Gifts via Bank Transfer</b></p> */}
              {/* <p>For USA: City Bank Account #: 207864984 | Routing #: 321171184</p> */}
              <ul>
                {/* <li><i class="ti-location-pin"></i> 315 West 33rd Street New York, NY 10001</li> */}
                <li>
                  Contact Information
                  <br />
                  NASEEM ALBERT RASHID
                  <br />
                  Founder And Chairman Of ( AIM INTERNATIONAL Ministry)
                </li>

                <li>
                  <i class="ti-mobile"></i> ( +1 (925) 503-7747 )
                </li>
                <li>
                  <i class="ti-mobile"></i> Whats App. Phone Number. ( +1 (925) 503-7747)
                </li>

                <li>
                  <i class="ti-email"></i> naseemalbert77@gmail.com
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 xs-padding">
            {/* <div class="contact-form"> */}
            {/* <h3>Send Your Donations</h3> */}
            {/* <form action="contact.php" method="post" id="ajax_form" class="form-horizontal"> */}
            {/* <ul>
                                  
                                    <li>
                                   <img className="w-25" src='./img/MasterCard-Logo-1996.png'/>

                                    </li>


                                    <li>
                                   <img className="w-25 mt-4" src='./img/visacard.png  '/>

                                    </li>
                                    <li>
                                   <img className="w-25 mt-4" src='./img/paypal5.png  '/>

                                    </li>
                                    <li>
                                   <img className="w-25 mt-4" src='./img/download-removebg-preview.png  '/>

                                    </li>
                                    
                                </ul> */}

            {/* <Link to="/not-found"   class="default-btn">Give Now</Link> */}
            {/* <Link to='/donate'> */}
            {/* <a href="https://link.clover.com/urlshortener/kWwcf5" 
                                style={{backgroundColor:'#f7b865',border:'1px solid #f7b865',minHeight:'50px',color:'#fff',padding:'10px 20px',textDecoration:'none',bordeRadius:'4px'}}
                                >Give Now</a> */}
            {/* </Link> */}

            {/* <div id="form-messages" class="alert" role="alert"></div> */}
            {/* </form> */}
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default DonateLandingPage;
