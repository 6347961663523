import React from "react";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <div>
      <header
        id="header"
        class="header-section"
        style={{ position: "fixed", width: "100%" }}
      >
        <div class="top-header">
          <div class="container">
            <div class="top-content-wrap row">
              <div class="col-sm-8">
                <ul class="left-info">
                  <li>
                    <a href="#">
                      <i class="ti-email"></i>naseemalbert77@gmail.com
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="ti-mobile"></i>(+1 (925) 503-7747)
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-sm-4 d-none d-md-block">
                <ul class="right-info">
                  <li>
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <nav
          class="navbar navbar-expand-lg navbar-light bg-light"
          style={{
            width: "100%",
          }}
        >
          {/* <a class="navbar-brand border border-danger " href="#"> */}
          <img
            className="ml-5 LogoMove "
            style={{ height: "100px" }}
            src="img/AIM logo final.png"
          />
          <p className="Albert" style={{ fontWeight: "bolder" }}>
            Albert International Ministry
          </p>

          {/* </a> */}
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto"></ul>
            <span class="navbar-text">
              <ul id="mainmenu" class="nav navbar-nav nav-menu">
                <li class="active">
                  {" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/ourministery">Our Ministery</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>

                <li>
                  {" "}
                  <Link to="/testimoney">Testimony</Link>
                </li>

                <li>
                  {" "}
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
              {/* <Link to="/donate" class="default-btn">Give Now</Link> */}
            </span>
          </div>
        </nav>
      </header>

      <div class="header-height"></div>
    </div>
  );
};

export default Header;
