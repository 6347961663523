import React from "react";
import Footer from "./Footer";
import Header from "./Header";
const Contact = () => {
  return (
    <div>
      <Header />

      <section class="contact-section padding">
        <div id="google_map"></div>
        <div class="container" style={{ marginTop: "10%" }}>
          <div class="row contact-wrap">
            <div class="col-md-6 xs-padding">
              <div class="contact-info">
                <h3>Get in touch</h3>
                <p>
                  The secret to happiness lies in helping others. Never
                  underestimate the difference YOU can make in the lives of the
                  poor, the abused and the helpless.
                </p>
                <p>
                  The secret to happiness lies in helping others. Never
                  underestimate the difference.
                </p>
                <ul>
                  {/* <li><i class="ti-location-pin"></i> 315 West 33rd Street New York, NY 10001</li> */}
                  <li>
                    Contact Information
                    <br />
                    NASEEM ALBERT RASHID
                    <br />
                    Founder And Chairman Of ( AIM INTERNATIONAL Ministry)
                  </li>

                  <li>
                    <i class="ti-mobile"></i> ( +1 (925) 503-7747 )
                  </li>
                  <li>
                    <i class="ti-mobile"></i> Whats App. Phone Number. (+1 (925)
                    503-7747)
                  </li>

                  <li>
                    <i class="ti-email"></i> naseemalbert77@gmail.com
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-6 xs-padding">
              <div class="contact-form">
                <h3>Drop us a line</h3>
                <form
                  action="contact.php"
                  method="post"
                  id="ajax_form"
                  class="form-horizontal"
                >
                  <div class="form-group colum-row row">
                    <div class="col-sm-6">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        class="form-control"
                        placeholder="Name"
                        required
                      />
                    </div>
                    <div class="col-sm-6">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        class="form-control"
                        placeholder="Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <textarea
                        id="message"
                        name="message"
                        cols="30"
                        rows="5"
                        class="form-control message"
                        placeholder="Message"
                        required
                      ></textarea>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-12">
                      <button id="submit" class="default-btn" type="submit">
                        Send Message
                      </button>
                    </div>
                  </div>
                  <div id="form-messages" class="alert" role="alert"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Contact;
