// import Carousel from 'react-bootstrap/Carousel';

// function UncontrolledExample() {
//   return (
//     <Carousel>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src="img/banner4.png"
//           alt="First slide"
//         />
   
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src="img/banner2.png"
//           alt="Second slide"
//         />

     
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src="img/banner.png"
//           alt="Third slide"
//         />

       
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src="img/banner5.png"
//           alt="First slide"
//         />
       
//       </Carousel.Item>
//       <Carousel.Item>
//         <img
//           className="d-block w-100"
//           src="img/banner7.png"
//           alt="Second slide"
//         />

       
//       </Carousel.Item>

//     </Carousel>
//   );
// }

// export default UncontrolledExample;


// import { AccessTimeFilled } from '@mui/icons-material';
// import React from 'react'
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1
//   }
// };
// function App() {
//   return (
//  <>
//  <Carousel responsive={responsive}
//  swipeable={true}
//  draggable={true}
//  showDots={true}
//  autoPlay={true}


//  >
//   <div >
// <img style={{width:"100%",height:'100vh'}} src="img/banner4.png"/>
//   </div>
//   <div>
//   <img style={{width:"100%",height:'100vh'}} src="img/banner2.png"/>

//   </div>
//   <div>
//   <img style={{width:"100%",height:'100vh'}} src="img/banner.png"/>

//   </div>
//   <div >
// <img style={{width:"100%",height:'100vh'}} src="img/banner4.png"/>
//   </div>
//   <div>
//   <img style={{width:"100%",height:'100vh'}} src="img/banner2.png"/>

//   </div>
//   <div>
//   <img style={{width:"100%",height:'100vh'}} src="img/banner.png"/>

//   </div>

// </Carousel>
//  </>
//   );
// }

// export default App;


// import AwesomeSlider from 'react-awesome-slider';
// import AwsSliderStyles from 'react-awesome-slider/src/styles.scss';

// const slider = (
//   <AwesomeSlider cssModule={styles}>
//     <div data-src="img/banner4.png" />
//     <div data-src="/path/to/image-1.png" />
//     <div data-src="/path/to/image-2.jpg" />
//   </AwesomeSlider>
// );

// import React, { Component } from "react";
// import "slick-carousel/slick/slick.css";
// import banner01 from "../src/assests/photo-1451187580459-43490279c0fa.avif"
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";

// export default class SimpleSlider extends Component {
//   render() {
//     const settings = {
//       dots: true,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1
//     };
//     return (
//       <div>
//         <h2> Single Item</h2>
//         <Slider>
//           <div>
//             <img src={banner01} />

//             <h3>1</h3>
//           </div>
//           <div>
//             <img src={banner01} />

//             <h3>2</h3>
//           </div>
//           <div>
//             <img src={banner01} />

//             <h3>3</h3>
//           </div>

//         </Slider>
//       </div>
//     );
//   }
// }

// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
 
// const slider = (
//   <AwesomeSlider
//     media={[
//       {
//         source: '/path/to/image-0.png',
//       },
//       {
//         source: '/path/to/image-1.png',
//       },
//       {
//         source: '/path/to/image-2.png',
//       },
//     ]}
//   />
// );
import React from 'react'

const SliderHero = () => {
  return (
    <div>
     



<div id="carouselExampleIndicators" class="carousel slide" style={{marginTop:'15%'}} data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img class="d-block w-100" src="img/banner4.png" alt="First slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="img/banner2.png" alt="Second slide"/>
    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="img/banner.png" alt="Third slide"/>
    </div>
  </div>
  <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>
    </div>
  )
}

export default SliderHero
