import React from "react";

const Footer = () => {
  return (
    <div>
      <section class="widget-section padding">
        <div class="container">
          <div class="widget-wrap row">
            <div class="col-md-4 xs-padding">
              <div class="widget-content">
                <img
                  src="img/AIM logo footer.png"
                  style={{ width: "35%" }}
                  alt="logo"
                />
                <p style={{ fontWeight: "bolder", color: "white" }}>
                  Albert International Ministry
                </p>
                <p>
                  <b>Mark 12.: 31</b>
                </p>
                <p>
                  thou shalt love the neighbor's as thyself. There is none other
                  Commencement greater than this.{" "}
                </p>
                <ul class="social-icon">
                  <li>
                    <a href="#">
                      <i class="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-pinterest"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-3 xs-padding">
              <div class="widget-content">
                {/* <h3>Recent Campaigns</h3> */}
                <ul class="widget-link">
                  <li>
                    <a href="#" style={{ color: "#f7b865" }}>
                      <b>
                        Albert international ministry is a non-profit entity
                        registered as 501 (c)(3). All donations made to Albert
                        international ministry are tax exempt from federal
                        income tax.
                      </b>
                    </a>
                  </li>
                  <li>
                    <a href="#"> </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-3 xs-padding">
              <div class="widget-content">
                <h3>AIM Location</h3>
                <ul class="address">
                  <i class="fa-brands fa-whatsapp"></i>
                  <li style={{ color: "white" }}>
                    <i class="ti-email"></i> info@aiminternationalministry.org
                  </li>

                  <i style={{ color: "#f7b865" }} class="fa-brands fa-whatsapp">
                    {" "}
                    <span
                      style={{
                        color: "white",
                        marginLeft: "15px",
                        fontSize: "14px",
                      }}
                    >
                      +1 (925) 503-7747
                    </span>
                  </i>
                  <li style={{ color: "white" }}>
                    <i class="ti-world"></i> aiminternationalministry.org
                  </li>
                  <li style={{ color: "white" }}>
                    <i class="ti-location-pin"></i>California, USA
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-2 xs-padding">
              <div class="widget-content">
                <h3>Contact Us </h3>

                <ul class="address">
                  <li style={{ color: "white" }}>
                    <i class="ti-mobile"></i>+1 (925) 503-7747
                  </li>
                  <img src="img/do.png" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 sm-padding">
              <div class="copyright">
                &copy; 2022 Albert International Ministry Powered by Joshua
                Lazar at Zeofix Technologies IT
              </div>
            </div>
            <div class="col-md-6 sm-padding">
              <ul class="footer-social">
                <li>
                  <a href="#">Orders</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Report Problem</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
