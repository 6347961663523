// Init
import React from "react";

// Importing Components
import Header from "../components/Header";
import Prmo from "../components/promo";
import RecentCauses from "../components/RecentCauses";
import About from "../components/About";
import Feature from "../components/Feature";
import Team from "../components/Team";
import Gallery from "../components/Gallery";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";
import Upcommingevent from "../components/upcommingevent";
import SliderComp from "../components/SliderComp";
import DonateLandingPage from "../components/DonateLandingPage";
import MainSilder from "../components/mainSilder";
import Event from "../components/Event";
import AddSection from "../components/AddSection";
// Home Component
export default function Home() {
  return (
    <div>
      <Header />
      <Prmo />

      <Team />
      {/* <Upcommingevent/>   */}
      {/* <Event/> */}
      {/* <AddSection/> */}
      {/* <MainSilder/> */}

      <RecentCauses />
      <Feature />

      <About />
      <Gallery />
      <Testimonial />
      <DonateLandingPage />
      <Footer />
    </div>
  );
}
