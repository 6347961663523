import React from 'react'

const Feature = () => {
  return (
    <div>
          {/* <section class="campaigns-section bd-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 xs-padding">
                        <div class="campaigns-wrap">
                            <h4>Up Coming Event</h4>
                            <h2>12th Nov  will be at  Yonsei Baptist Church  Youhanabad, Lahore </h2>
                        
                            <div class="donation-box">
                                
                            </div>
                        
                        </div>
                    </div>
                    <div class="col-md-6 xs-padding">
                        <div class="video-wrap">
                            <img src="img/allpostor.jpeg" alt="video"/>
                           
                        </div>
                    </div>
               
                </div>
            </div>
        </section> */}


        <div>
     
    </div>
    </div>
  )
}

export default Feature