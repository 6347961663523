import React from 'react'
import Header from '../components/Header'
const Testimoney = () => {
    return (
        <div>
            <Header />
            <section class="blog-section bg-grey pt-5 ">
                <div class="container"style={{ marginTop: '15%' }}  >
                    <div class="row " style={{ marginTop: '15%' }}>
                        <div class="col-lg-9 sm-padding">
                            <div class="blog-items single-post row">
                                <img src="img/Untitled design (12).jpg" alt="blog post" />
                                <h2>History, Background &amp; Testimony</h2>
                                <div class="meta-info">
                                    <span>
                                        <i class="ti-user"></i> Written By <a href="#">NASEEM ALBERT RASHID</a>
                                    </span>

                                </div>
                                <p>
                                Pastor Naseem was born in Karachi Pakistan into a pastor house, oldest of nine siblings. He is a second generation servant of God. Pastor Naseem’s testimony starts when he was only one year old, he lost his eye sight and some of Friends of my
                                    Parents advised them to take me your Son to a which craft to prayer for me.
                                    My father told them that we are Christians and we do not believe in theses
                                    things, one day a man of God to our house and prayed for me and by THE
                                    GRACE of God, I received my eyes sight. And my Parents accepted JESUS
                                    CHRIST as their personal Lord and Savior.
                                </p>
                                <p>In 1973, One Of my Friend Advised to go to Kuwait. And I was living my life
                                    there, My Parents kept asking me to go to Church,
                                    Some how in 1983, I went back to Pakistan, and got Married A girl from Lahore
                                    and left my wife with my Family in Karachi. And I went back to Kuwait.</p>

                                <p>One day in Kuwait while I was going to see my Friend and as I was Standing on
                                    bus Stand I HEARD VERY CLEAR VOICE SAYING TO ME,
                                    Why you do not obey to Your Father:s letter and go to Church,
                                    I said to my self Its only God who speaks, what do you want me to do&gt;&gt;</p>

                                <p>After this I started looking for Church In Kuwait, came in my mind my father
                                    use to say go URDU Speaking Church. I found a Church and I went in Church
                                    and I saw a Preacher was from USA, I was only waiting when Preacher will
                                    finish and will give alter Call,
                                    And I accepted Jesus Christ As my LORD and Savior, And requested to
                                    Preacher to give me Water Baptism 1984</p>
                                <p>After this I realized that I am a married man, I decided to go back home
                                    Pakistan, I don’t now why But I canceled my Kuwait Resident VISA In 1985.
                                    I came back to Pakistan Karachi When Holy Spirit was dealing with me In
                                    Kuwait, I came to know when I reach Home my Wife told, she took Water
                                    Baptism from our mother Church  AOG Drigh Road Pastor Zia Paul </p>
                                <p>I than I told her all story how I heard GODS Voice in Kuwait At Bus Stand,
                                    My Wife Received Holy Spirit Before Me, I received HOLY SPIRIT after one Year
                                    while I was in England Bible College.
                                    We started going to our mother Church and Started helping my Pastor, AOG.</p>
                                <p>
                                    And now my Mother Started saying that  you don’t have children you get
                                    Married, Another Girl..  I told my mother Wait Mom
                                    And we don’t know Gods Plane we went to see, Yasmeens Parents in Lahore,
                                    And next day was Sunday 11 th of August  as Church Finished I said in my
                                    Mouth Lord That Lady will come and say I want to Pray for If you wanted me to
                                    bless With children from the Girl I have Marred. As I whispered to my self, that
                                </p>
                                <p>
                                    lady I do not know about her, she came and she said  Can I Pray with you
                                    THANKS TO HOLY SPIRIT SHE CAME AND PRAYED FOR US&gt;
                                    Right after 8 month we went for Checkup in Hospital UCH Is Christian
                                    Hospital, when my wife out she told me Doctor are Saying under Baby there is
                                    a Ball of Cancer, and we cannot touch right now we will wait tell the day comes
                                    for Baby birth.
                                    Same Pastor who Baptism me In Kuwait,  DR. Bajat From USA was Visiting in
                                    Pakistan Lahore AT FGA Church Pastor Sardars some how I went to meeting
                                    And Pastor Asked me where is YASMEEN my wife, I said to Pastor she is not
                                    feeling well, he said tomorrow is last meeting You must bring Her, and next
                                    day we went in meeting, at his altor call we went in front for Prayer Pastor
                                    Never Prayed any special Prayer  He Just said my touching Yasmeen LORD
                                    Bless and we came home  while Yasmeen was sleeping on her bed, by HIS
                                    Grace the ball, Doctor said is Cancer. Was Destroyed .
                                </p>
                                <p>
                                    THIS WAS All HIS Plan TO BIULD MY FAITH&lt; THAT THE GOD I HEARD IS alive
                                    GOD. I never knew God wanted me to Work in HIS Vinyard, My PASTOR ZIA
                                    PAUL, said I Feel you Must Serve Lord, My Pastor asked me to write my
                                    testimony and share it,
                                    Pastor sended to Mattersey Hall Bible College ENGLAND UK, I was Sponsored
                                    for Two years, for Study.
                                    I came back 1988 September, My Pastor Asked from where to Start My
                                    Ministry. I said VILLAGE, So God Arranged Village Called 424 Chuck .
                                </p>
                                <p>After 1year we left Village 424, and came to Lahore, ( long Story ) I was able to
                                    Start</p>
                                <p><b>AIM International Ministry.</b></p>
                                <p>We have Four Provinces in Pakistan, I was able to Visit and Preach Gospel,
                                    many came to the Lord and Many were Healed through our Ministry. My older
                                    son said in my heart is to start TV Christian Channel, I told him to do it For
                                    Gods Glory, many testimony came that Pepole are blessed through our TV
                                    Program, Gospel Songs and Kids short store and Short message and Praying
                                    for Needy People. Our Christian TV Channel was as many colonies, and city.</p>
                                <p>And by His Grace we were able to start  Bible School in Lahore 3 years FREE
                                    Education, We Provided them three years, Boarding and Lodging for free.
                                    We took (9) girls and (13) boys. From different citys and Villages, buy this I
                                    was able to reach more Village and cities through Students.</p>
                                <p>God Opened door for me to Visit for Sharing Word of GOD, DUBI Zambia,
                                    Philppines and in Philppines I was able to preach on Radio, and I was 2006 I
                                    was Able to Preach in USA.</p>
                                <p><b>THIS HOW GOD BROUGHT US IN USA.</b></p>
                                <p>Were facing problem through the Muslim Community, we slept in our Van on
                                    the Road Side for Few days and for 6 months running.
                                    Finally we all came to USA, By His Grace,
                                    IN USA I was able to Visit different Churches ( Fuji Church) URDU Speaking
                                    Church) And English Speaking Church) Preaching The Good News of Jesus
                                    Christ, And I was Able to Baptized TWO Indian SINGH.

                                </p>
                                <p>Still we have team back in Pakistan Looking after the Churches in Different
                                    Cities and Village, ( Pastor Team IN LAHORE, Islamabad, Fasialabad)
                                    And some Pastor Friends in Pakistan,</p>

                            </div>
                        </div>
                        <div class="col-lg-3 sm-padding">
                            <div class="sidebar-wrap">

                                <div class="sidebar-widget mb-50">
                                    <h4>Ministries</h4>
                                    <ul class="cat-list">
                                        <li><a href="#">Children Ministry</a><span></span></li>
                                        <li><a href="#">Helping Poor</a><span></span></li>
                                        <li><a href="#">Youth Ministry</a><span></span></li>
                                        <li><a href="#">Prayer Ministry</a><span></span></li>
                                        <li><a href="#">Womens Ministry</a><span></span></li>
                                        <li><a href="#">Village Ministry</a><span></span></li>
                                        <li><a href="#">Outreach Ministry</a><span></span></li>
                                        <li><a href="#">Convention/ Crusade</a><span></span></li>
                                        <li><a href="#">Pastoral network.</a><span></span></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Testimoney