import React from 'react'

const Maintain = () => {
  return (
    <div>
              <section class="error-section padding">
            <div class="container">
                <div class="error-content text-center">
                    <h3>Urgently Notice</h3>
                    <h5>Due to some technical issues,</h5>
                    <p>we are not taking any Donations right now. Thanks Lords blessing to all ? ( Senior Pastor Naseem Albert)</p>
                 
                </div>
            </div>
        </section>
    </div>
  )
}

export default Maintain
