import React from 'react'
import { Link } from 'react-router-dom'
import SliderComp from './SliderComp'

const Gallery = () => {
  return (
    <div>
         <div class="pager-header">
            <div class="container"style={{marginTop:'40%'}}>
                <div class="page-content"style={{marginTop:'20%'}}>
                    <h2>A Third  of  Pakistan Underwater amin its worst Floods in  History</h2>
                    <p>Help today because tomorrow you may be the one who <br/>needs more helping!</p>
                   
                </div>
            </div>
        </div>
          <section class="gallery-section bg-grey bd-bottom padding">
            <div class="container">
               <div class="section-heading text-center mb-40">
                    <h2>Devastating floods in Pakistan </h2>
                    <span class="heading-border"></span>
                    <p>Aim International Ministry is on the ground working with partners to help children and families.</p>
                    {/* <a href="https://link.clover.com/urlshortener/kWwcf5" 
                                style={{backgroundColor:'#f7b865',border:'1px solid #f7b865',minHeight:'50px',color:'#fff',padding:'10px 20px',textDecoration:'none',bordeRadius:'4px'}}
                                >Give Now</a> */}
                                 {/* <Link to="/not-found"   class="default-btn">Give Now</Link> */}
                </div>
                <div class="gallery-items ">
                  <SliderComp/>
                </div>
                <div class="gallery-items row">
                    <div class="col-lg-4 col-sm-6 single-item branding design">
                        <div class="gallery-wrap">
                            <img src="img/flood02.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood02.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing wordpress">
                        <div class="gallery-wrap">
                            <img src="img/flood09.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood09.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item wordpress design branding">
                        <div class="gallery-wrap">
                            <img src="img/flood10.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood10.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item design branding wordpress">
                        <div class="gallery-wrap">
                            <img src="img/flood11.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood11.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item branding marketing">
                        <div class="gallery-wrap">
                            <img src="img/flood12.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood12.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 single-item marketing design">
                        <div class="gallery-wrap">
                            <img src="img/flood13.jpg" alt="gallery"/>
                            <div class="hover">
                                <a class="img-popup" data-gall="galleryimg" href="img/flood13.jpg"><i class="ti-image"></i></a> 
                            </div>
                        </div>
                    </div>
                </div>








                
               
            </div>
        </section>
    </div>
  )
}

export default Gallery